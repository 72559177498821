<template lang="pug">
  footer.footer
    div.footer__container
      div.footer__top
        router-link(:to="{ name: 'HomePage' }" v-scroll-to="'#steps1'" class="footer__logo logo")
          img(src="@/assets/images/logo.svg" alt="logo")
        div.footer__actions
          a.footer__link-actions.footer__link-actions--tel(href="tel:+380630203007") +380 63 02 03 007
          a.footer__link-actions.footer__link-actions--mail(href="mailto:mail@drivelink.com.ua") mail@drivelink.com.ua
          a.footer__link-actions.footer__link-actions--inst(href="https://www.instagram.com/drivelink.com.ua/") @drivelink.com.ua
        nav.footer__menu.menu
          ul.footer__list
            li.footer__item
              router-link(to="", v-scroll-to="'#steps1'", class="footer__link") Головна
            li.footer__item
              router-link(to="", v-scroll-to="'#steps2'", class="footer__link") Про Drivelink
            li.footer__item
              router-link(to="", v-scroll-to="'#steps3'", class="footer__link") Характеристики
            li.footer__item
              router-link(to="", v-scroll-to="'#steps4'", class="footer__link") Ціни
            li.footer__item
              router-link(to="", v-scroll-to="'#steps5'", class="footer__link") Контакти
      div.footer__copyright
        p © DRIVELINK 2023
        span |
        p Всі права захищено.
</template>

<script>
export default {
  name: 'FooterBlock'
}
</script>
